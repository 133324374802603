import React from "react";
import InternalLink from "../components/InternalLink";

function Surveys() {
    return (
      <article className="flex flex-col xs:flex-row min-h-screen text-gray-900">
        <aside className="bg-gray-400 xs:w-1/2 md:w-1/3 lg:w-3/10 p-2 sm:p-4 lg:p-8">
          <div className="sticky px-4 pb-4 " style={{ top: "80px" }}>
            <h1 className="pt-8 text-xl font-bold text-blue-900">
              Employee Opinion Surveys
            </h1>
            <p className="mt-8 text-sm font-bold whitespace-no-wrap">
              Clients:
            </p>
            <p className="font-semibold text-blue-900">
              LF Zerfoss &amp; Associates,
            </p>
            <p className="font-semibold text-blue-900">
              Western Carolina Industries
            </p>
            <p className="mt-8 text-sm font-bold whitespace-no-wrap">
              Overview:
            </p>
            <p className="font-medium leading-relaxed">
              Leveraged Optical Mark Reader technology to reduce the processing
              cost and turnaround time by 90%, while also increasing the
              accuracy of the survey results and adding a wide range of
              customization and comparison options.
            </p>
            <p className="mt-4 font-medium leading-relaxed">
              The end result was a product that was in active commercial use for
              nearly two decades.
            </p>
          </div>
        </aside>
        <section className="px-4 sm:px-8 py-8 sm:py-12 lg:py-16 xs:w-1/2 md:w-2/3 lg:w-7/10 font-medium leading-relaxed bg-cover">
          <p className="">
            Two of my clients were Management Consulting firms who jointly
            created and administered Employee Opinion Surveys to industrial and
            manufacturing companies. At the time, all survey responses were
            manually entered into a computer in order to tabulate the results
            and produce reports. The staffing and time required to enter the
            data and produce the finished reports was substantial, and we
            devised a solution that reduced their turnaround from weeks to 1 or
            2 days.
          </p>

          <p className="mt-6">
            The problem was quite interesting. The surveys targeted Managerial,
            Office, and Front-Line staff with a set of up to 30 questions, 13 of
            which produced results that strongly correlated to the likelihood of
            unionization. While those 13 core questions appeared in each survey,
            the remaining questions were chosen in consultation with management
            to target specific areas of interest at each company.
          </p>

          <p className="mt-6">
            We saw this as an ideal candidate for the Optical Mark Reader
            technology that we were integrating into our{" "}
            <InternalLink to="/projects/payroll">Payroll</InternalLink> product.
          </p>

          <p className="mt-6">
            I designed and developed a system that would allow them to select
            the set of questions to be used for each survey, scan the survey
            forms, process the data, and produce the final statistical reports.
            I made the system flexible enough to handle changes in the
            departmental structure of the surveyed companies so they could still
            produce accurate comparisons to prior results.
          </p>

          <p className="mt-6">
            Because all of the questions were maintained in a common "pool" used
            across many organizations, the results were aggregated for all
            surveyed companies. This allowed for comparisons across market
            segments and industry types, between and within regions, to prior
            surveys at the same company and plant, as well as different
            divisions or departments whether they were within a single location
            or spanned multiple plants which might be surveyed at different
            times.
          </p>

          <p className="mt-6">
            Using custom Optical Mark Reader template forms, the customer could
            print survey forms on-demand to match the selected questions. With
            data collection handled by the OMR Scanner, data entry and
            processing only required a single staff member and was often
            completed the same day the survey forms were returned to the main
            office.
          </p>

          <p className="mt-6">
            Initially created in 1987, it was re-written in 1992 when the firms
            decided to operate separately. LF Zerfoss &amp; Associates continued
            to use my survey system, with only minor updates, until 2007.
          </p>
        </section>
      </article>
    );
}

export default Surveys;
