import React from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

function NavigationLink(props) {
  const { to, label, clickAction, collapsed, addClass } = props;

  const pathname = props.location.pathname;

  return (
    <Link
      to={to}
      onClick={clickAction}
      className={`${addClass} 
          mt-1 sm:mt-0 px-2 py-1 rounded-lg whitespace-no-wrap 
          transition-bg transition-ease-out 
          focus:outline-none hover:bg-blue-400 hover:text-gray-900 focus:bg-blue-400 
          sm:p-0 sm:focus:text-blue-300 sm:hover:text-blue-300 ${
            pathname === to ? "bg-blue-700 sm:text-gray-300" : ""
          } 
          ${
            collapsed
              ? "sm:bg-blue-900 sm:focus:bg-blue-900 sm:hover:bg-blue-900"
              : "sm:bg-blue-800 sm:focus:bg-blue-800 sm:hover:bg-blue-800"
          }`}
    >
      {label}
    </Link>
  );
}

NavigationLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  clickAction: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  addClass: PropTypes.string
};

export default withRouter(NavigationLink);
