import React, { Component } from "react";
import PropTypes from "prop-types";

class WordFader extends Component {
  constructor(props) {
    super();

    this.words = JSON.parse(props.words);
    this.fadeDelay = props.fadeDelay;
    this.wordDelay = props.wordDelay;
    this.children = props.children;
    this.currentWord = 0;
    this.isVisible = true;
    this.isStrike = false;
    this.elementRef = React.createRef();
  }

  fade() {
    if (this.element === null) {
      return;
    }

    let delay = this.fadeDelay;

    if (this.isVisible && this.isStrike) {
      this.element.classList.add("opacity-0");
      this.isVisible = false;
      this.isStrike = false;
    } else if (this.isVisible && !this.isStrike) {
      this.element.classList.add("strikethrough");
      this.isStrike = true;
    } else {
      delay = this.wordDelay;
      this.currentWord++;
      this.element.innerText = this.words[this.currentWord];
      this.element.classList.remove("strikethrough", "opacity-0");
      this.isVisible = true;
      this.isStrike = false;
    }

    if (this.currentWord < this.words.length - 1) {
      setTimeout(() => {
        this.fade();
      }, delay);
    } else {
      this.element.classList.add("font-semibold", "text-blue-900");
    }
  }

  componentDidMount() {
    this.element = this.elementRef.current;
    setTimeout(() => {
      this.fade();
    }, 1500);
  }

  render() {
    return (
      <span
        className="word-fader relative transition-opacity transition-ease-out transition-delay-250"
        ref={this.elementRef}
      >
        {this.children}
      </span>
    );
  }
}

WordFader.propTypes = {
  words: PropTypes.string.isRequired,
  fadeDelay: PropTypes.number.isRequired,
  wordDelay: PropTypes.number.isRequired,
  children: PropTypes.any.isRequired,
}

export default WordFader;