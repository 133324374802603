import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./components/Footer";
import CollapsingHeader from "./components/CollapsingHeader";
import About from "./pages/About";
import Employment from "./pages/Employment";
import Projects from "./pages/Projects";
import Surveys from "./pages/Surveys";
import Payroll from "./pages/Payroll";
import Impairment from "./pages/Impairment";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = { showSplash: false };
    this.menuItems = [
      { url: "/", title: "Home" },
      { url: "/experience", title: "Experience" },
      { url: "/projects", title: "Projects" },
    ]

    const observerAction = entries => {
      let entry = entries[0];

      if (entry.intersectionRatio === 1 && !this.state.showSplash) {
        this.setState({ showSplash: true });
        document.querySelector("header").classList.add("splash");
      } else if (entry.intersectionRatio !== 1 && this.state.showSplash) {
        this.setState({ showSplash: false });
        document.querySelector("header").classList.remove("splash");
      }
    };

    this.observer = new IntersectionObserver(observerAction, {
      root: document.querySelector("#scrollArea"),
      threshold: 1,
      rootMargin: "0px 0px 0px 0px"
    });
  }

  componentDidMount() {
    this.observer.observe(document.querySelector("#scroller"));
  }

  render() {
    return (
      <Router>
        <ScrollToTop />
        <div className="min-h-full flex flex-col">
          <div
            id="scroller"
            className={`h-2 m-0 bg-blue-800 transition-bg transition-ease-out ${
              this.state.showSplash ? "bg-blue-800" : "bg-blue-900"
            }`}
          >
            &nbsp;
          </div>
          <CollapsingHeader showSplash={this.state.showSplash} menuItems={this.menuItems} />
          <main className="content bg-gray-100 text-gray-900">
            <Route exact path={["/"]} component={About} />
            <Route exact path={["/experience"]} component={Employment} />
            <Route exact path={["/projects"]} component={Projects} />
            <Route path={["/projects/opinionsurveys"]} component={Surveys} />
            <Route path={["/projects/payroll"]} component={Payroll} />
            <Route path={["/projects/disability"]} component={Impairment} />
          </main>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
