import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import Navigation from "./Navigation";

class CollapsingHeader extends Component {
  constructor() {
    super();
    this.menuHamburger = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="h-8 w-8 fill-current"
      >
        <path
          fillRule="evenodd"
          d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
        />
      </svg>
    );
    this.menuCancel = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="h-8 w-8 fill-current"
      >
        <path
          fillRule="evenodd"
          d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
        />
      </svg>
    );

    this.state = {
      menuButton: this.menuHamburger,
      menuOpen: false
    };

    this.closeMenu = this.closeMenu.bind(this);
  }

  closeMenu() {
    this.setState({ menuOpen: false, menuButton: this.menuHamburger });
  }

  render() {
    const showSplash = this.props.showSplash;

    const menuClick = e => {
      this.setState({ menuOpen: !this.state.menuOpen });

      let menuButton;
      if (this.state.menuOpen) {
        menuButton = this.menuHamburger;
      } else {
        menuButton = this.menuCancel;
      }
      this.setState({ menuButton: menuButton });
    };

    return (
      <React.Fragment>
        <header
          className={`sticky top-0 text-blue-100 z-50 transition-bg transition-ease-out ${
            showSplash ? "bg-blue-800" : "bg-blue-900"
          }`}
        >
          <div className="wrapper block sm:flex justify-between px-2 py-2 sm:px-4 sm:py-4 md:px-8">
            <div className="flex justify-between">
              <div className="details flex items-center w-full">
                <Link to="/">
                  <div
                    className={`rounded-full overflow-hidden border-gray-500 shrink-none transition-all transition-ease-out ${
                      showSplash
                        ? " h-16 w-16 border-2 xxs:h-18 xxs:w-18 xs:border-3 xs:h-20 xs:w-20 sm:h-24 sm:w-24 md:h-32 md:w-32 "
                        : " h-12 w-12 border-2"
                    }`}
                  >
                    <img
                      src={require("../images/portrait.jpg")}
                      alt="Avatar"
                      className="h-full w-full object-fill"
                    />
                  </div>
                </Link>

                <div className="flex flex-col ml-2 lg:ml-3">
                  <div
                    className={`my-name font-medium sm:font-semibold sm:text-lg md:text-xl lg:text-2xl whitespace-no-wrap`}
                  >
                    Stephen Cochran
                  </div>
                  <div
                    className={`my-details text-sm md:text-center sm:text-base whitespace-no-wrap ${
                      showSplash ? "" : "hidden"
                    }`}
                  >
                    Full Stack Developer
                  </div>
                  <div
                    className={`my-location hidden mt-1 text-sm whitespace-no-wrap w-full xs:text-base md:text-center ${
                      showSplash ? "xs:block" : ""
                    }`}
                  >
                    <div className="flex justify-between items-center md:justify-center">
                      <a
                        href="mailto:contact@stephen-cochran.dev"
                        title="Email me"
                        className="ml-6 md:ml-0"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="inline-block h-6 w-6 fill-current text-blue-300 hover:text-blue-500 focus:text-blue-500 focus:outline-none"
                          viewBox="0 0 24 24"
                        >
                          <path d="M22 8.62V18a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8.62l9.55 4.77a1 1 0 0 0 .9 0L22 8.62z" />
                          <path d="M12 11.38l-10-5V6c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v.38l-10 5z" />
                        </svg>
                      </a>
                      <a
                        href="https://linkedin.com/in/srcochran"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mr-6 md:mr-0 md:ml-8 text-blue-600 hover:text-blue-300 focus:text-blue-300 focus:outline-none rounded-sm border-1 border-blue-400"
                        title="View my LinkedIn profile"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.0"
                          viewBox="0 0 32 32"
                          className="h-6 w-6 inline-block text-blue-600 fill-current hover:text-blue-400 focus:text-blue-400 focus:outline-none"
                        >
                          <path d="M32 30c0 1.104-.896 2-2 2H2c-1.104 0-2-.896-2-2V2C0 .896.896 0 2 0h28c1.104 0 2 .896 2 2v28z" />
                          <g fill="#FFF">
                            <path d="M7 11h4v14H7zM20.499 11c-2.791 0-3.271 1.018-3.499 2v-2h-4v14h4v-8c0-1.297.703-2 2-2 1.266 0 2 .688 2 2v8h4v-7c0-4-.521-7-4.501-7z" />
                            <circle cx="9" cy="8" r="2" />
                          </g>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <button
                onClick={menuClick}
                className="block text-blue-100 focus:outline-none focus:text-blue-400 hover:text-blue-400 sm:hidden"
              >
                {this.state.menuOpen ? this.menuCancel : this.menuHamburger}
              </button>
            </div>
            <Navigation
              menuOpen={this.state.menuOpen}
              headerCollapsed={!showSplash}
              clickAction={this.closeMenu}
              menuItems={this.props.menuItems ? this.props.menuItems: []}
            />
          </div>
        </header>
        <button
          className={`opacity-50 bg-black inset-0 h-full w-full z-40 ${
            this.state.menuOpen ? "fixed" : "hidden"
          }`}
          onClick={this.closeMenu}
        ></button>
      </React.Fragment>
    );
  }
}

CollapsingHeader.propTypes = {
  showSplash: PropTypes.bool.isRequired,
  menuItems: PropTypes.array.isRequired,
}

export default CollapsingHeader;
