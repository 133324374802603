import React from "react";
import WordFader from "../components/WordFader";
import HeroBackground from "../images/network-2402637_1280-small.jpg";

function About() {
    return (
      <React.Fragment>
        <section
          className="relative bg-cover bg-center"
          style={{ backgroundImage: `url(${HeroBackground})` }}
        >
          <div
            className="relative h-full w-full lg:px-auto py-20 sm:py-32 lg:py-40 font-medium text-center"
            style={{
              backgroundColor: "rgba(235,248,255,0.85)"
            }}
          >
            <h1 className="block sm:inline-block text-2xl md:text-4xl">
              Hello, I'm Stephen.
            </h1>
            <p className="block sm:inline-block sm:pl-2 text-2xl md:text-4xl">
              I{" "}
              <WordFader
                words='["write programs", "develop software", "build applications", "create solutions"]'
                fadeDelay="800"
                wordDelay="2500"
              >
                write programs
              </WordFader>
              .
            </p>

            <p className="mx-6 mt-8 text-lg sm:mx-12 sm:mt-12 md:text-xl lg:mt-16 lg:mx-auto lg:max-w-3xl lg:text-2xl">
              I'm always looking for interesting projects, and am currently
              available for new opportunities.
            </p>
            <a
              href="mailto:contact@stephen-cochran.dev"
              role="button"
              className="inline-block mt-8 sm:mt-12 lg:mt-16 px-4 py-2 text-xl text-blue-100 bg-blue-800 font-semibold border-1 border-white rounded-lg focus:bg-blue-100 focus:outline-none focus:text-blue-800 hover:bg-blue-100 hover:text-blue-800"
            >
              Contact me
            </a>
          </div>
        </section>
        <section className="md:py-12 lg:py-20 lg:max-w-5xl lg:mx-auto">
          <div className="flex flex-col md:flex-row">
            <div className="w-full px-8 py-8 text-lg text-left sm:px-12 md:py-0 md:w-1/2">
              <h2 className="text-xl font-semibold lg:text-2xl text-center">
                Background
              </h2>
              <p className="mt-4">
                I have more than 30 years of professional software development
                experience, ranging from vertical market commercial products to
                custom solution development for a wide array of clients.
              </p>
              <p className="mt-4">
                Most of my career has been focused on designing and building
                complete solutions from the ground up and I have spent the last
                9 years as a Developer Consultant serving clients in the public
                and private sectors.
              </p>
              <p className="mt-4">
                I am platform agnostic and have used my deep love of learning to
                transition between languages, tools, and frameworks many times
                to accommodate technological evolution and the demands of the
                project.
              </p>
            </div>
            <div className="w-full px-4 py-8 text-lg bg-blue-100 sm:px-8 md:py-0 md:pr-12 md:bg-gray-100 md:mt-0 md:w-1/2">
              <h2 className="text-xl font-semibold lg:text-2xl text-center">
                Current Skills
              </h2>
              <div className="flex justify-around">
                <div>
                  <ul className="mt-4">
                    <li className="font-medium">Microsoft .NET Platform</li>
                    <ul className="pl-4 text-base">
                      <li>C#, Linq, Entity Framework</li>
                      <li>ASP.Net MVC and Web API</li>
                      <li>Entity Framework</li>
                    </ul>
                    <li className="mt-2 font-medium">Web Development</li>
                    <ul className="pl-4 text-base">
                      <li>HTML5, CSS3, JavaScript</li>
                      <li>jQuery, React</li>
                      <li>TailwindCSS, BootStrap</li>
                      <li>PHP, Laravel, Drupal</li>
                    </ul>
                    <li className="mt-2 font-medium">Database</li>
                    <ul className="pl-4 text-base">
                      <li>SQL Server, T-SQL</li>
                      <li>MariaDB (MySQL)</li>
                    </ul>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
}

export default About;