import React from "react";
import EmploymentItem from "../components/EmploymentItem";
import InternalLink from "../components/InternalLink";

function Employment() {
    const showMore = e => {
      var target = e.target;
      var sib = target.previousElementSibling;

      if (sib.classList.contains("hidden")) {
        sib.classList.remove("hidden");
        target.innerHTML =
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="inline-block align-baseline h-6 w-6 fill-current"><path d="M8.7 13.7a1 1 0 1 1-1.4-1.4l4-4a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1-1.4 1.4L12 10.42l-3.3 3.3z"/></svg>&nbsp;Less Detail';
      } else {
        sib.classList.add("hidden");
        target.innerHTML =
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="inline-block align-baseline h-6 w-6 fill-current"><path d="M15.3 10.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"/></svg>&nbsp;More Detail';
      }
    };

    const showMoreButton = (
      <button
        onClick={showMore}
        className="mt-4 flex items-center cursor-pointer text-blue-800 font-medium focus:outline-none hover:text-blue-500 focus:text-blue-500"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="inline-block align-baseline h-6 w-6 fill-current"
        >
          <path d="M15.3 10.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z" />
        </svg>
        &nbsp;More Detail
      </button>
    );

    return (
      <section className="flex flex-col w-full">
        <h1 className="text-xl pt-4 pb-4 font-semibold text-center bg-blue-100 sm:bg-gray-100 sm:text-2xl lg:text-3xl">
          Experience
        </h1>

        <EmploymentItem
          company="Calibre Systems"
          title="Web Developer"
          dates="2019"
        >
          <p>
            Participated in the migration of the intranet of a division of the
            National Institutes of Health from SharePoint to Drupal.
          </p>
          <p className="mt-4">
            Developed a migration utility to export the current SharePoint site
            content and metadata into an intermediate XML format to be used to
            create Drupal nodes.
          </p>
          <p className="mt-4">
            Created data interchange processes to create Drupal user profiles
            from the NIH's user data repository.
          </p>
          <p className="mt-4">
            Configured Drupal and created modules to support the data
            interchange process.
          </p>
        </EmploymentItem>

        <EmploymentItem
          company="ThincIT / TCSC"
          title="Senior Consultant"
          dates="2011 - 2019"
        >
          <p>
            Software Development Consultant working on projects for clients that
            included national and regional Insurance companies, Government
            agencies (Local, State, and Federal), Financial Services firms,
            Industrial companies, and Non-Profit organizations.
          </p>
          <p className="mt-4">
            Developed a project management and tracking system in SharePoint to
            help Anthem manage their rollout of expanded coverage under the ACA.
          </p>
          <p className="mt-4">
            Participated in the development, maintence, and upgrade of the
            SharePoint intranet for a division of the National Institutes of
            Health. This included developing components, workflows, and
            utilities for the SharePoint site, data interchange processes, and
            data conversion/update utilities for migrating between SharePoint
            versions. Later, I was part of the team tasked with migrating the
            SharePoint intranet to Drupal.
          </p>
          <p className="mt-4">
            For the State of Delaware, I developed a sytem of utilities and ETL
            SQL Scripts to extract legacy crime-lab and medical examination data
            from Lotus Notes and migrate it as historical data into a modern
            Laboratory Information Management System.
          </p>
          <div className="hidden">
            <p className="mt-4">Other clients I served include:</p>
            <ul className="mt-2 list-disc">
              <li className="ml-4">Appomattox County Public Schools</li>
              <li className="ml-4">CarMax</li>
              <li className="ml-4">Centra Health</li>
              <li className="ml-4">Chemtreat, Inc</li>
              <li className="ml-4">Cherry Bekaert LLP</li>
              <li className="ml-4">GMAC</li>
              <li className="ml-4">Mutual Assurance Society of Virginia</li>
              <li className="ml-4">Luck Stone</li>
            </ul>
          </div>
          {showMoreButton}
        </EmploymentItem>

        <EmploymentItem
          company="UDR"
          title="Lead Developer"
          dates="2000 - 2011"
        >
          <p>
            Developed applications and processes to support the operations of a
            Real Estate Investment Trust that owned and managed several hundred
            apartment communities thoughout the USA.
          </p>
          <p className="mt-4">
            Created and maintained the ETL scripts and SSIS processes that
            loaded the daily operating and accounting data from the remote
            property management software at each site into our SQL Server
            database.
          </p>
          <p className="mt-4">
            Was reponsible for creating desktop and web applications in support
            of our operations and employees.
          </p>
          <p className="mt-4">
            Developed a interchange services to connect with remote systems and
            service providers.
          </p>
          <p className="mt-4">
            Built and maintained a wide array of reporting products to support
            our operations team and financial anaylists.
          </p>
        </EmploymentItem>

        <EmploymentItem
          company="ARCON / VerNova"
          title="Software Developer"
          dates="1995 - 2000"
        >
          <p>
            Responsible for architecting and creating vertical market software
            for the Independent Medical Examination and Functional Job Analysis
            markets.
          </p>
          <p className="mt-4">
            Created <InternalLink to="projects/disability">AIRS</InternalLink>,
            a commercial product that assisted physicians in the evaluation and
            calculation of permanent disability for Worker's Compensation
            claims. This was sold to physicians nationwide, and was also adopted
            by the Worker's Compensation Board of British Columbia.
          </p>
          <p className="mt-4">
            Developed a web-based Functional Job Analysis system, which was used
            to quantify the elements and tasks involved in performing a specific
            job or job function.
          </p>
          <p className="mt-4">
            Developed and maintained the company's public website.
          </p>
          <p className="mt-4">
            Installed, configured, and maintained the company's infrastructure,
            including the Servers, Network, and PBX.
          </p>
        </EmploymentItem>

        <EmploymentItem
          company="Insight Computer Services"
          title="Owner"
          dates="1992 - 1995"
        >
          <p>
            Provided Consulting, Computer Sales &amp; Service, and Software
            Development services to clients throughout Western North Carolina.
          </p>
          <p className="mt-4">
            Developed and managed the data processing system for Western
            Carolina Industries' yearly Wage and Benefits surveys, and was
            responsible for producing the final reports that were distributed to
            their clients and partners.
          </p>
          <p className="mt-4">
            Created and supported a new version of the{" "}
            <InternalLink to="/projects/opinionsurveys">
              Employee Opinion Survey
            </InternalLink>{" "}
            which was a cornerstone product for the management consultants at LF
            Zerfoss &amp; Associates. This software remained in continuous use
            until 2007.
          </p>
          <p className="mt-4">
            Installed and supported the in-office computers, LAN, and remote
            ticketing WAN for Great Smokey Mountain Railroad.
          </p>
          <div className="hidden">
            <p className="mt-4">
              Supported a wide range of clients, including:
            </p>
            <ul className="ml-4 mt-2 list-disc">
              <li className="">
                Alzhiemer's Disease and Related Disorders Association
              </li>
              <li>Belding Corticelli Thread</li>
              <li>Blue Ridge Parkway Visitor's Center</li>
              <li>Fugazy Travel</li>
            </ul>
            <p className="mt-4">... and many more</p>
          </div>
          {showMoreButton}
        </EmploymentItem>

        <EmploymentItem
          company="JA Erwin &amp; Associates"
          title="Software Developer"
          dates="1986 - 1992"
        >
          <p>
            Created vertical market commercial software as well as custom
            solutions for regional businesses.
          </p>
          <p className="mt-4">
            Developed a commercial{" "}
            <InternalLink to="/projects/payroll">Payroll</InternalLink> system
            with capabilities targeting the piece-rate payroll market, and more
            particularly for Sheltered Workshops. This software facliltated
            compliance with Department of Labor regulations regarding
            piece-rate, guaranteed minimum wage, and sub-minimum wage employers.
          </p>
          <p>
            Developed a commercial module for the SBT Payroll Software market to
            calculate, track and report 401(k) contributions and employer
            matching contributions.
          </p>
          <p className="mt-4">
            Created an{" "}
            <InternalLink to="/projects/opinionsurveys">
              Employee Opinion Survey
            </InternalLink>{" "}
            product used by management consultants to assist in assessing and
            improving the workplace and employee job satisfaction for hundreds
            of businesses throughout the region.
          </p>
          <p className="mt-4">
            Developed software for clients across a variety of industries.
          </p>
        </EmploymentItem>

        <EmploymentItem company="Freelance" title="" dates="1984 - 1986">
          <p>
            Developed a song selection and rotation automation system for WBMU
            Radio.
          </p>
          <p className="mt-4">
            Modified and extended an Accounts Receivable system for Loom
            Products, Inc, to automate the generation of national and
            international bills of lading for their freight shipments.
          </p>
        </EmploymentItem>

        <EmploymentItem
          company="InterAct Computer Systems"
          title="Developer"
          dates="1983 - 1984"
        >
          <p>
            Participated in the creation of applications supporting business
            customers. Projects included:
          </p>
          <p className="mt-4">
            An application for a Consumer Credit Counseling Service that managed
            the automatic disbursment of funds to creditors, based upon
            repayment rates and schedules negotiated between the debtor and the
            creditors.
          </p>
          <p className="mt-4">
            An applicaiton supporting a regional Agricultural Co-op, aggregating
            bulk purchases of goods and correctly expensing the funds according
            to each member's share of the purchase.
          </p>
        </EmploymentItem>
      </section>
    );
}

export default Employment;