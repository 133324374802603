import React from "react";
import ExternalLink from "../components/ExternalLink";

function Impairment() {
    return (
      <article className="flex flex-col xs:flex-row min-h-screen text-gray-900">
        <aside className="bg-gray-400 xs:w-1/2 md:w-1/3 lg:w-3/10 p-2 sm:p-4 lg:p-8">
          <div className="px-4 pb-4" style={{ top: "80px" }}>
            <h1 className="pt-8 text-xl font-bold text-blue-900">
              Disability Rating System
            </h1>
            <p className="mt-8 text-sm font-bold whitespace-no-wrap">
              Clients:
            </p>
            <p className="font-semibold text-blue-900">
              Physicians, IME Clinics
            </p>
            <p className="mt-8 text-sm font-bold whitespace-no-wrap">
              Overview:
            </p>
            <p className="font-medium leading-relaxed">
              When an person is diagnosed with a permanent disability and files
              a Worker's Compensation claim, the extent of the disability is
              evaluated by a series of measurements and complex calculations.
            </p>
            <p className="mt-4 font-medium leading-relaxed">
              I developed a product that allowed the evaluator to quickly record
              their measurements and accurately calculated the level of
              disability according to the legally established guidelines for 38
              US States, as well as for the Canadian province of British
              Columbia.
            </p>
          </div>
        </aside>
        <section className="px-4 sm:px-8 py-8 sm:py-12 lg:py-16 xs:w-1/2 md:w-2/3 lg:w-7/10 font-medium leading-relaxed bg-cover">
          <p className="">
            Applied Rehabilitation Concepts, Inc (ARCON) offered a hardware and
            software system for performing Functional Capacity Evaluations
            (FCEs). These were a series of strength tests, range of motion
            measurements, and timed exercises that could help determine a
            person's ability to perform a job, or to identify a post-injury loss
            of capability and the post-recovery restoration of function after
            medical treatment and rehabilitation/physical therapy.
          </p>

          <p className="mt-6">
            While an FCE can help determine the existence of an impairment, the
            process of determining the extent of permanent impairment for
            Worker's Compensation claims requires an Independent Medical
            Examination (IME) that follows a very specific set of criteria and
            involves a complex set of calculations that are highly susceptible
            to error if performed manually.
          </p>

          <p className="mt-6">
            I created the ARCON Impairment Rating System (AIRS) as a complement
            to the existing suite of FCE products which facilitated the
            collection of individual impairment measurements and diagnoses, then
            automated the calculation of the resulting permanent impairment
            rating. The result was a detailed report of the patient's impariment
            diagnoses, with an full breakout of the calculations involved, which
            would then be used to support and defend a Worker's Compensation
            disability benefits claim.
          </p>

          <p className="mt-6">
            To give a sense of process, measurements take into consideration
            factors such as amputations, range of motion restrictions, loss of
            sensation, and neuropathy at each joint and/or extremity of the
            body. Additional factors are included, such as spinal degeneration,
            neurological impairment, arthritis, loss of vision or hearing, and
            many other diagnosable losses.
          </p>

          <p className="mt-6">
            These individual factors each produce an impairment value at the
            site of the injury (for example, the thumb of the dominant hand).
            Multiple impairments to the thumb (partial amputation, loss of range
            of motion in the remaining joint, persistent pain) result in
            individual ratings, which are combined by a formula specific to that
            location. These are then applied along with other ratings according
            to a series of combinatorial rules and ratios as you progress
            towards a rating of the whole person.{" "}
          </p>

          <p className="mt-6">
            In other words, an injury to the thumb can be expressed as a partial
            disability to that thumb, which is then combined with other
            impairments to the same hand to calculate a value for the whole
            hand. These are then combined with any any impariments to that arm
            and expressed as a disability of the arm. This process is repeated
            for impairments to the other extremities, the spine/torso, the head,
            and other less site-specifc impairments, which are finally combined
            to produce a "whole person" disability percentage.
          </p>

          <p className="mt-6">
            Previously, these calculations were performed manually by the
            physician and their staff.
          </p>

          <p className="mt-6">
            In 38 states, IMEs are performed using the American Medical
            Association's{" "}
            <ExternalLink to="https://commerce.ama-assn.org/store/ui/search/searchResults?search=Guides%20to%20the%20Evaluation%20of%20Permanent%20Impairment">
              Guides to the Evaluation of Permanent Impairment
            </ExternalLink>
            . Unfortunately there has been no standardization beyond that and
            the states individually use different versions of the Guides, each
            of which has different impairment values, methods of measurement,
            and calculation instructions. My software supported 4 editions of
            the Guides: the 3rd Edition, 3rd Edition (Revised), 4th Edition, and
            the 5th Edition.
          </p>

          <p className="mt-6">
            I also created a customized version of AIRS for the Worker's
            Compensation Board of British Columbia in close consultation with
            their disability evaluation team, which accommodated their use of
            modifications to the 3rd Edition (Revised) Guides.
          </p>

          <p className="mt-6">
            As a result, we created a system that enabled IMEs to be performed
            accurately and eliminated the chance of calculation errors. To my
            knowledge, there were no successful legal or procedural challenges
            to the results of an IME performed using my software.
          </p>
        </section>
      </article>
    );
}

export default Impairment;
