import React from "react";
import NavigationLink from "./NavigationLink";
import PropTypes from "prop-types";

function Navigation(props) {
  var menuOpen = props.menuOpen;
  var collapsed = props.headerCollapsed;
  var menuClick = props.clickAction;
  let renderedMenu = [];

  if (props.menuItems) {
    props.menuItems.forEach(item => {
      renderedMenu.push(
        <NavigationLink
          to={item.url}
          label={item.title}
          clickAction={menuClick}
          collapsed={collapsed}
          addClass="sm:ml-3 md:ml-6 lg:ml-8 first:ml-0"
        />
      );
    });
  }

  return (
    <React.Fragment>
      <nav
        className={`flex-col mt-2 font-medium text-base sm:flex sm:flex-row md:mt-0 sm:items-center sm:font-normal md:font-medium lg:font-semibold lg:text-lg ${
          menuOpen ? "flex" : "hidden"
        }`}
      >
        {renderedMenu}
      </nav>
    </React.Fragment>
  );
}

Navigation.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  headerCollapsed: PropTypes.bool.isRequired,
  clickAction: PropTypes.func.isRequired,
  menuItems: PropTypes.array.isRequired,
};

export default Navigation;
