import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

function InternalLink(props) {
  const { to, children } = props;
  return (
    <Link
      to={to}
      className="text-blue-800 cursor-pointer font-semibold hover:text-blue-500 focus:text-blue-500 focus:outline-none hover:outline-none"
    >
      {children}
    </Link>
  );
}

InternalLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired
}

export default InternalLink;
